

@import "variables";

@import '../node_modules/normalize-scss/sass/normalize/import-now';
@import '../node_modules/material-design-lite/src/button/_button.scss';
@import '../node_modules/material-design-lite/src/ripple/_ripple.scss';

@import "typography";
@import "icons";
@import "layout";
@import "about";
@import "download";
@import "footer";

* {
  box-sizing: border-box;
}

body {
  background: $colorBackground;
}