

.About {
  background: $colorPrimary;
  padding: 80px 120px;

  @media (max-width: $breakPointLarge) {
    padding: 48px 10vw;
  }

  @media (max-width: $breakPointSmall) {
    padding: 32px;
  }

  @media (max-width: $breakPointMobile) {
    max-width: 100%;
    overflow-x: hidden;
  }

  &__title,
  &__text {
    transition: $transition;
  }

  &__title {
    font-size: 48px;
    font-weight: bold;
    line-height: 48px;

    @media (max-width: $breakPointSmall) {
      font-size: 40px;
    }

    @media (max-width: $breakPointMobile) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    margin-top: 8px;

    @media (max-width: $breakPointSmall) {
      margin-top: 0;
    }

    @media (max-width: $breakPointMobileXs) {
      margin-top: 12px;
    }
  }

  &__screen {
    width: 1024px;
    height: 621px;
    margin-top: 80px;
    border-radius: 4px;
    box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.48);

    @media (max-width: $breakPointLarge) {
      width: 80vw;
      height: calc((621/1024)*80vw);
      margin-top: 48px;
    }

    @media (max-width: $breakPointSmall) {
      width: 100%;
      height: calc((621/1024)*100%);
      margin-top: 24px;
      box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.32);
    }

    @media (max-width: $breakPointMobileXs) {
      width: 200%;
    }
  }
}