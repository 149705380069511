

.Download {
  background: $colorPrimary;
  padding: 0 120px 80px 120px;

  @media (max-width: $breakPointLarge) {
    padding: 0 10vw 48px 10vw;
  }

  @media (max-width: $breakPointSmall) {
    padding: 0 32px 32px 32px;
  }

  .mdl-button--accent.mdl-button--accent.mdl-button--raised {
    height: 48px;
    line-height: 48px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 2px;
    background-color: $colorTextPrimary;
    color: $colorPrimary;
    text-transform: uppercase;
    font-weight: 700;

    @media (max-width: $breakPointMobileXs) {
      width: 100%;
    }
  }

  &__link {
    color: $colorTextPrimary;
    text-decoration: none;
    margin-left: 32px;

    &:hover, &--hover {
      text-decoration: underline;
    }

    @media (max-width: $breakPointMobile) {
      display: block;
      margin: 24px 0 0 0;
    }

    @media (max-width: $breakPointMobileXs) {
      line-height: 20px;
      font-size: 13px;
      text-align: center;
    }
  }
}