

.Footer {
  height: 120px;
  margin-bottom: 80px;

  @media (max-width: $breakPointLarge) {
    margin-bottom: 48px;
  }

  @media (max-width: $breakPointSmall) {
    margin-bottom: 0;
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  .Github {
    width: 452px;
    height: 120px;
    float: left;
    margin-left: 120px;
    padding-left: 40px;
    background: $colorDark;
    padding-right: 44px;

    @media (max-width: $breakPointLarge) {
      width: calc(50% - 10vw);
      margin-left: 10vw;
    }

    @media (max-width: $breakPointMedium) {
      width: 366px;
    }

    @media (max-width: $breakPointSmall) {
      width: 100%;
      float: none;
      margin-left: 0;
      padding-right: 32px;
      background-position: 32px 40px;
    }

    @media (max-width: $breakPointMobileXs) {
      text-align: center;
    }

    &__textLink {
      display: inline-block;
      padding-left: 56px;
      margin-top: 40px;
      background: $iconGithub 0 0 no-repeat;
      color: $colorTextSecondary;
      line-height: 40px;
      text-decoration: none;
      transition: $transition;

      &:hover, &--hover {
        color: $colorTextPrimary;
      }

      &:active, &--active {
        opacity: $activeOpacity;
        transform: $activeScale;
      }
    }

    &__icon {
      display: block;
      background: $iconLink center center no-repeat;
      background-size: 24px 24px;
      overflow: hidden;
      text-indent: -9999px;
    }

    .mdl-button {
      float: right;
      margin-top: 44px;
      padding-top: 2px;

      @media (max-width: $breakPointMobileXs) {
        display: none;
      }
    }
  }

  .Author {
    width: 452px;
    height: 120px;
    float: left;
    padding-right: 43px;

    @media (max-width: $breakPointLarge) {
      width: calc(50% - 10vw);
      padding-right: 0;
    }

    @media (max-width: $breakPointMedium) {
      width: calc(100% - 20vw - 366px);
      padding-right: 0;
    }

    @media (max-width: $breakPointSmall) {
      width: 100%;
      float: none;
      padding-right: 0;
      padding-top: 24px;
      text-align: center;
    }

    &__iconLink {
      display: inline-block;
      height: 42px;
      line-height: 42px;
      background: $iconForcandies right center no-repeat;
      background-size: 38px 42px;
      float: right;
      margin-top: 39px;
      margin-left: 13px;
      padding-right: 56px;
      color: $colorTextSecondary;
      text-decoration: none;
      transition: $transition;

      @media (max-width: $breakPointSmall) {
        float: none;
        margin: 0 auto;
        background-position: center top;
        text-align: center;
        padding: 40px 0;
      }

      &:hover, &--hover {
        color: $colorTextPrimary;
      }

      &:active, &--active {
        opacity: $activeOpacity;
        transform: $activeScale;
      }
    }
  }
}