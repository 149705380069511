

.Container {
  display: block;
  width: 1024px;
  margin: auto;

  @media (max-width: $breakPointLarge) {
    width: 100%;
  }
}