

$colorBackground: rgb(38, 50, 56);
$colorPrimary: rgb(66, 133, 244);
$colorDark: rgba(0, 0, 0, 0.4);
$colorTextPrimary: rgb(255, 255, 255);
$colorTextSecondary: rgb(158, 158, 158);

$ripple-bg-color: rgba(0, 0, 0, 0.4) !important;

$breakPointLarge: 1320px;
$breakPointMedium: 960px;
$breakPointSmall: 728px;
$breakPointMobile: 620px;
$breakPointMobileXs: 420px;

// Transitions
$transition: all .2s ease-in-out;
$activeOpacity: 0.75;
$activeScale: scale3d(0.96, 0.96, 1);