

@import url('//fonts.googleapis.com/css?family=Roboto:400,500,700&subset=latin-ext');

html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: 'Roboto', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  quotes: "“" "”";
}

* {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga', 'kern';
}

h1, h2, h3, h4, h5 {
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;
  color: $colorTextPrimary;
}

p {
  color: $colorTextPrimary;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

a {
  font-size: 14px;
}